<lib-clean-card>
  <div class="rowwrap gap1">
    
    <!-- Power Section -->
    <div class="col gap1">
      <div class="row gap1 ">
        <mat-card-title>Power</mat-card-title>
      </div>
      
      <div class="col gap1">
        <lib-mat-form-entity [dataPack]="powerRailPositive"></lib-mat-form-entity>
        <lib-mat-form-entity [dataPack]="powerRailNegative"></lib-mat-form-entity>
        <lib-mat-form-entity [dataPack]="powerRailFiveVolts"></lib-mat-form-entity>
      </div>
      
      <lib-auto-update-loading-indicator
        [data$]="dataService.singleModuleData$"
        [updateData$]="savePower$"
        loadingLabel="Saving data"
      >
        <div class="row end-align" style="margin-top: 1rem;">
          <app-brand-primary-button
            (click$)="savePower$.next()"
            [disabled]="!(formGroupPower | formValid)"
            theme="positive"
          >
            Save Power Data
          </app-brand-primary-button>
        </div>
      </lib-auto-update-loading-indicator>
    </div>
    <!-- Physical properties Section -->
    <div class="col gap1">
      <div class="row gap1 ">
        <mat-card-title>Physical properties</mat-card-title>
      </div>
      
      <div class="col gap1">
        <lib-mat-form-entity [dataPack]="depth"></lib-mat-form-entity>
        <lib-mat-form-entity [dataPack]="weight"></lib-mat-form-entity>
      </div>
      
      <lib-auto-update-loading-indicator
        [data$]="dataService.singleModuleData$"
        [updateData$]="savePhysical$"
        loadingLabel="Saving data"
      >
        <div class="row end-align" style="margin-top: 1rem;">
          <app-brand-primary-button
            (click$)="savePhysical$.next()"
            [disabled]="!(formGroupPhysical | formValid)"
            theme="positive"
          >
            Save physical properties
          </app-brand-primary-button>
        </div>
      </lib-auto-update-loading-indicator>
    </div>
    
    <mat-divider></mat-divider>
    
    <!-- Panel Section -->
    <div class="col gap1">
      <div class="row gap1 ">
        <mat-card-title>Panel</mat-card-title>
      </div>
      
      <div class="row gap1 ">
        <lib-file-drag-host
          [isImageOnlyMode]="true"
          [multipleFilesMode]="false"
          acceptedFileType="image/jpeg,image/jpg"
        ></lib-file-drag-host>
        
        <div class="col gap1">
          <lib-mat-form-entity
            [dataPack]="panelType"
            [disableVoidSelection]="true"
          ></lib-mat-form-entity>
          
          <lib-mat-form-entity [dataPack]="panelDescription"></lib-mat-form-entity>
        </div>
      </div>
      
      <lib-auto-update-loading-indicator
        [data$]="dataService.singleModuleData$"
        [updateData$]="savePanels$"
        loadingLabel="Saving data"
      >
        <div class="row end-align" style="margin-top: 1rem;">
          <app-brand-primary-button
            (click$)="savePanels$.next()"
            [disabled]="!(formGroupPanel | formValid) || !(fileDragHostService.files$ | async)?.length"
            theme="positive"
          >
            Add Panel
          </app-brand-primary-button>
        </div>
      </lib-auto-update-loading-indicator>
    </div>
    
    <mat-divider></mat-divider>
    <!-- INs and OUTs Section -->
    <div class="col gap1">
      <!-- INs Section -->
      <div class="col gap1">
        <div class="row gap1 ">
          <mat-card-title>INs</mat-card-title>
          <mat-card-subtitle>Leave fields blank if unknown</mat-card-subtitle>
        </div>
        
        <app-module-editor-adder-line (add$)="addIN$.next($event)"></app-module-editor-adder-line>
        
        <div class="col gap1 leftBorderS" style="margin-top: 1rem;">
          <ng-container *ngFor="let item of INs$ | async; let i = index">
            <app-module-editor-cv-form-line
              [item]="item"
              (removeRequest$)="removeIN$.next(i)"
            ></app-module-editor-cv-form-line>
          </ng-container>
        </div>
      </div>
      
      <!-- OUTs Section -->
      <div class="col gap1">
        <div class="row gap1 ">
          <mat-card-title>OUTs</mat-card-title>
          <mat-card-subtitle>Leave fields blank if unknown</mat-card-subtitle>
        </div>
        
        <app-module-editor-adder-line (add$)="addOUT$.next($event)"></app-module-editor-adder-line>
        
        <div class="col gap1 leftBorderS" style="margin-top: 1rem;">
          <ng-container *ngFor="let item of OUTs$ | async; let i = index">
            <app-module-editor-cv-form-line
              [item]="item"
              (removeRequest$)="removeOUT$.next(i)"
            ></app-module-editor-cv-form-line>
          </ng-container>
        </div>
      </div>
      
      <div class="row gap1 " style="margin-top: 1rem;">
        <div class="col"
        >
          <mat-card-subtitle>
            Any changes you make will affect what all other users see.
          </mat-card-subtitle>
          <mat-card-subtitle>
            Please update data only if it is incorrect or missing. If you are unsure, leave it as is.
          </mat-card-subtitle>
        </div>
      </div>
      
      <lib-auto-update-loading-indicator
        [data$]="dataService.singleModuleData$"
        [updateData$]="saveInsOuts$"
        loadingLabel="Saving data"
      >
        <div class="row gap2" style="margin-top: 1rem;">
          <app-brand-primary-button
            (click$)="saveInsOuts$.next()"
            [disabled]="!(formGroupA | formValid) || !(formGroupB | formValid)"
            theme="positive"
          >
            Save INs and OUTs
          </app-brand-primary-button>
        </div>
      </lib-auto-update-loading-indicator>
    </div>
  </div>
</lib-clean-card>