.box {
  display:   flex;
  flex-wrap: wrap;
  gap:       .5rem;
}

.box > * {
  flex:      1 1 16rem;
  min-width: 16rem
}
