.skeleton {
  background-color: rgba(255, 255, 255, 0.36);
  -webkit-filter:   invert(75%);
  filter:           invert(75%);
  font-size:        small;
  border-radius:    1rem;
  //max-width:        20rem;
  color:            rgba(255, 255, 255, 0);
}

.m1 {
  margin-right: 5%;
  //margin-left:  5%;

}

.m2 {
  margin-right: 15%;
  //margin-left:  15%;

}

.m3 {
  margin-right: 2%;
  //margin-left:  0%;

}
